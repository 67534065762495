import React from 'react'
import EmailMe from '../EmailMe'
import { Wrapper, Nav, NavItem, NavLink } from './style'

const Menu = () =>
  <Wrapper>
    <Nav>
      <NavItem>
        <a href="https://github.com/SaadAAkash" rel="noopener noreferrer" target="_blank">Git</a>
      </NavItem>
      <NavItem>
        <a href="https://twitter.com/SaadAAkash" rel="noopener noreferrer" target="_blank">Tweet</a>
      </NavItem>
      <NavItem>
        <a href="https://medium.com/@SaadAAkash" rel="noopener noreferrer" target="_blank">Medium</a>
      </NavItem>
      <NavItem>
        <a href="https://scholar.google.com/citations?user=33PbaVUAAAAJ" rel="noopener noreferrer" target="_blank">Research</a>
      </NavItem>
      <NavItem highlight>
        <a href="https://www.linkedin.com/in/SaadAAkash" rel="noopener noreferrer" target="_blank">Linked In:</a>
      </NavItem>
      {/* <NavItem>
        <a href="https://stackoverflow.com/u/11040422" rel="noopener noreferrer" target="_blank">Stack OverFlow</a>
      </NavItem> */}
    </Nav>
    <Nav>
      <NavItem>
        <NavLink to='/code'>Code</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to='/career'>Career</NavLink>
      </NavItem>
      <NavItem>
        <NavLink to='/contest'>Contest</NavLink>
      </NavItem>
      <NavItem>
        <a href="https://contents.saad.ninja/" rel="noopener noreferrer" target="_blank">Contents</a>
      </NavItem>
      <NavItem highlight><EmailMe text="Contacts:"/>
    </NavItem>
    </Nav>
  </Wrapper>

export default Menu

// export const LAMBDA_ENDPOINT = 'https://kvz60gz535.execute-api.us-east-1.amazonaws.com/prod/distance'
// export const IS_PROD = process.env.NODE_ENV === 'production'
export const META = {
  common: {
    image: '/images/saad-a-akash-meta-image.png',
  },
  index: {
    title: 'Saad A Akash | Portfolio',
    description: 'Meet Saad - A polyglot Software Engineer with a knack for design, process & research. Specializes in technical project management with a humbling experience in UX Research'
  },
  profile: {
    title: 'Saad A Akash | Career',
    description: 'A career timeline of wearing many hats while exporing multiple exciting industry domains'
  },
  works: {
    title: 'Saad A Akash | Works',
    description: 'A curated selection handpicked from a wide range of works'
  },
  contest: {
    title: 'Saad A Akash | Contests',
    description: 'A brief timeline of selected honors & awards won at different competitions'
  }
}

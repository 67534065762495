import React from 'react'
import { getCurrentYear } from '../../utils/functions'
import { Wrapper, Item } from './style'

const Footer = () =>
  <Wrapper>
    
    <Item position='left'>
      <span>Shoutout to </span>
      <a target="_blank" rel="noopener noreferrer" href='https://dribbble.com/michelemazzucco'>Michelle</a>
    </Item>
    
    <Item position='right'>
      {/* Updated on {getCurrentYear()} */}
    </Item>
  
  </Wrapper>

export default Footer
